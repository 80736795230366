<template lang="html">
  <!--
  We are using the official Drag and Drop API:
  https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API
  -->
  <div
    v-on:dragenter.prevent="toggleActive"
    v-on:dragleave.prevent="toggleActive"
    v-on:dragover.prevent
    v-on:drop.prevent="toggleActive"
    :class="{ 'is-active': isActive }"
    class="dropzone"
  >
    <span>Drag and drop file</span>
    <span>OR</span>
    <label class="text-start" :for="dropzoneName">Select file </label>
    <input
      type="file"
      :name="dropzoneName"
      :id="dropzoneName"
      class="dropzone-file"
      :class="dropzoneName"
    />
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: ["name"],
  setup(props) {
    const dropzoneName = ref(props.name);
    const isActive = ref(false);
    const toggleActive = () => {
      isActive.value = !isActive.value;
    };

    return { dropzoneName, isActive, toggleActive };
  },
};
</script>

<style lang="scss" scoped>
.dropzone {
  width: 400px;
  max-width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  border: 2px dashed #41b883;
  color: #f8f9fa;
  transition: 0.3s ease all;

  &.is-active {
    background-color: #41b883;
    border: 2px dashed #f8f9fa;

    label {
      background-color: #f8f9fa;
      color: #212529;
    }
  }

  label {
    padding: 8px 12px 5px 12px;
    background-color: #41b883;
    cursor: pointer;
    transition: 0.3s ease all;

    &:hover {
      background-color: #f8f9fa;
      color: #212529;
    }
  }
  input {
    display: none;
  }
}
</style>
