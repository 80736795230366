<template>
  <transition name="modal-animation">
    <div v-show="modalActive" class="modal-box">
      <transition name="modal-animation-inner">
        <div v-show="modalActive" class="modal-box-inner shadow">
          <!-- Modal Content -->
          <slot />
          <button
            v-on:click="close"
            type="button"
            name="close-modal"
            class="btn btn-secondary mt-4"
          >
            Close
          </button>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: "FileUpload",
  props: ["modalActive"],
  setup(props, { emit }) {
    const close = () => {
      emit("closeModal");
    };
    return { close };
  },
};
</script>

<style lang="scss" scoped>
/* Annimations */
.modal-animation-enter-active,
.modal-animation-enter-active {
  transition: opacity 0.4s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}

/* Styling */
.modal-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0;
  left: 0;

  .modal-box-inner {
    max-width: 100vw;
    background-color: #288d92;
    padding: 2em 4em;
  }
}
</style>
