// PINIA STORE handling the TEMPLATES

import axios from "axios";
import swal from "sweetalert";
import { defineStore } from "pinia";
import { useAuthStore } from "./authentication";

export const useTemplateStore = defineStore("templates", {
  state: () => {
    return {
      active_template_id: null,
      template_list: [],
    };
  },
  getters: {
    headers: () => {
      const authStore = useAuthStore();
      return {
        headers: { Authorization: authStore.token },
      };
    },
    active_template: (state) => {
      return state.template_list.find(
        (template) => template.template_id === state.active_template_id
      );
    },
  },
  actions: {
    getActiveTemplate() {
      axios
        .get("/api/user", this.headers)
        .then((res) => {
          this.active_template_id = res.data.active_template;
        })
        .catch((err) => {
          console.error({
            status: err.response.status,
            message: err.response.data,
          });
        });
    },

    setActiveTemplate(templateId) {
      const url = "/api/user/set-active-template/" + templateId;
      axios
        .patch(url, {}, this.headers)
        .then((res) => {
          swal({
            title: "Success",
            text: res.data,
          });
        })
        .then(() => this.getActiveTemplate())
        .catch((err) => {
          console.error({
            status: err.response.status,
            message: err.response.data,
          });
        });
    },

    getTemplateList() {
      axios
        .get("/api/template", this.headers)
        .then((res) => (this.template_list = res.data))
        .catch((err) => {
          console.error({
            status: err.response.status,
            message: err.response.data,
          });
        });
    },

    uploadTemplate(uploadFileForm) {
      // Using Sweetalert for personnalized alerts:
      // https://sweetalert.js.org/guides/#getting-started
      axios
        .post("/api/template/upload", uploadFileForm, this.headers)
        .then(() => {
          swal("Success!", "Your file has been uploaded!", "success");
          this.getTemplateList();
        })
        .catch(function (error) {
          let status_code = error.response.status;

          if (status_code === 403) {
            swal("Mmmmh ...", error.response.data, "info");
          } else if (status_code === 409) {
            swal("Mmmmh ...", error.response.data, "info");
          } else {
            console.error({
              status: error.response.status,
              message: error.response.data,
            });
          }
        });
    },

    deleteTemplate() {
      const url = "/api/template/delete/" + this.active_template_id;
      const active_template_name = this.active_template.filename;

      swal({
        icon: "warning",
        title: "Are you sure ?",
        text:
          "Once deleted, the template <" +
          active_template_name +
          "> will no longer be accessible.",
        buttons: ["Cancel", "Yes, delete !"],
        dangerMode: true,
      }).then((deleteFile) => {
        if (deleteFile) {
          axios
            .delete(url, this.headers)
            .then(() => {
              swal({
                text: "Template has been deleted!",
                icon: "success",
              });
              this.getTemplateList();
            })
            .catch((err) => {
              console.error({
                status: err.response.status,
                message: err.response.data,
              });
            });
        }
      });
    },
  },
});
