<template>
  <section>
    <!-- Settings -->
    <div class="container bg-dark px-5 py-4 my-5">
      <h3 class="align-middle shadow border rounded pt-2 mb-5 bg-light">
        Settings
      </h3>
      <div class="row my-5">
        <div class="col">
          <h5>Username: {{ authStore.username ? authStore.username : "" }}</h5>
        </div>
        <div class="col">
          <h5>
            Organization: {{ authStore.user_org ? authStore.user_org : "" }}
          </h5>
        </div>
      </div>
      <h3 class="section-title">Template:</h3>

      <!-- Templates -->
      <div class="row mb-5">
        <div class="col-12 col-md-6">
          <select
            class="form-select"
            aria-label="Default select example"
            v-on:change="setTemplate"
          >
            <option selected>
              {{
                template_store.active_template
                  ? "Currently Selected: " +
                    template_store.active_template.filename
                  : "No template selected."
              }}
            </option>
            <option
              v-for="(t, index) in template_store.template_list"
              :key="index"
              :value="t.template_id"
            >
              {{ t.filename }}
            </option>
          </select>
        </div>
        <TemplateUpload />
      </div>

      <!-- Form Fields -->
      <h3 class="section-title">Form Fields:</h3>
      <div class="row px-2 mb-5">
        <div
          class="col-12 col-md-6 pt-3 bg-light rounded text-start field-table-container"
        >
          <table v-if="fieldsAsJson" class="table field-table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Field Name</th>
                <th scope="col">Field Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(field, index) in fieldsAsJson" :key="index">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ field.name }}</td>
                <td>{{ field.type }}</td>
              </tr>
            </tbody>
          </table>
          <p v-else class="m-0">No fields</p>
        </div>
        <FieldUpload />
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/authentication";
import { useTemplateStore } from "@/stores/templates";
import { useFormFieldStore } from "@/stores/formFields";
import TemplateUpload from "@/components/TemplateUpload.vue";
import FieldUpload from "@/components/FieldUpload.vue";

// Variables
const router = useRouter();
const authStore = useAuthStore();
const template_store = useTemplateStore();
const field_store = useFormFieldStore();
const fieldsAsJson = ref(null);

// Methods
template_store.getTemplateList();
template_store.getActiveTemplate();
field_store.getOrganizationFields();

function setTemplate(changeEvent) {
  template_store.setActiveTemplate(changeEvent.target.value);
}

function convertFieldsToJson(fields) {
  let fieldList = [];
  fields.forEach((value) => {
    fieldList.push({ name: value.name, type: value.type });
  });
  fieldsAsJson.value = fieldList;
}

//Subscription events
field_store.$subscribe(() => {
  convertFieldsToJson(field_store.organization_fields);
});

onMounted(() => {
  if (!authStore.user_id) {
    router.push({ name: "Login" });
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.section-title {
  text-align: left;
  color: #f8f9fa;
}

.field-table-container {
  max-height: 500px;
  overflow-y: scroll;
}
h5 {
  color: #f8f9fa;
}
</style>
