// PINIA STORE handling the FORM FIELDS

import axios from "axios";
import swal from "sweetalert";
import { defineStore } from "pinia";
import { useAuthStore } from "./authentication";

export const useFormFieldStore = defineStore("form_fields", {
  state: () => {
    return {
      organization_fields: null,
    };
  },
  getters: {
    headers: () => {
      const authStore = useAuthStore();
      return {
        headers: { Authorization: authStore.token },
      };
    },
  },
  actions: {
    getOrganizationFields() {
      axios
        .get("/api/fields", this.headers)
        .then((res) => {
          this.organization_fields = res.data.fields;
        })
        .catch((err) => {
          console.error({
            status: err.response.status,
            message: err.response.data,
          });
        });
    },

    uploadNewFields(fields_as_json) {
      axios
        .post("/api/fields", fields_as_json, this.headers)
        .then(() => {
          swal("Success!", "Your file has been uploaded!", "success");
          this.getOrganizationFields();
        })
        .catch((err) => {
          console.error({
            status: err.response.status,
            message: err.response.data,
          });
        });
    },

    postNewFields(uploadedFieldsFile) {
      // Read the contents of the .json file
      // Step 1: assert that it is JSON
      if (uploadedFieldsFile.name.endsWith(".json")) {
        // Step 2: Create a FileReader instance set its onload to read the file
        // https://developer.mozilla.org/en-US/docs/Web/API/FileReader
        // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsText
        const reader = new FileReader();

        reader.onload = (e) => {
          const content = e.target.result;
          const fields_as_json = JSON.parse(content);

          // Step 3: Make the request (onload can take a bit of time)
          this.uploadNewFields(fields_as_json);
        };

        // Step 4: Run the `readAsText` method of the FileReader
        reader.readAsText(uploadedFieldsFile);
      } else {
        swal(
          "Oh no!",
          "Your file does not seem to be a `.json` file. Please try again with a valid `.json` file.",
          "error"
        );
      }
    },

    deleteFields() {
      swal({
        icon: "warning",
        title: "Are you sure ?",
        text: "This will delete ALL the fields linked to your organization. This means your colleagues will also not have any access to the current fields anymore.",
        buttons: ["Cancel", "Yes, delete !"],
        dangerMode: true,
      }).then((deleteFormFields) => {
        if (deleteFormFields) {
          axios
            .delete("/api/fields", this.headers)
            .then((res) => {
              swal({
                text: res.data,
                icon: "success",
              });
              this.getOrganizationFields();
            })
            .catch((err) => {
              console.error({
                status: err.response.status,
                message: err.response.data,
              });
            });
        }
      });
    },
  },
});
