<template lang="html">
  <div class="col-12 col-md-6 button-container">
    <button
      class="btn btn-primary upload-btn"
      type="button"
      v-on:click="toggleModal"
    >
      Upload Form Fields
    </button>
    <button
      class="btn btn-danger delete-btn"
      type="button"
      v-on:click="deleteFormFields"
    >
      Delete Fields
    </button>
    <div class="text-start mt-5 ms-5">
      <p class="color-light">
        Uploading fields will append the uploaded fields to the existing ones.
        We will not check if a field already exists. This might lead to
        duplicates, so be careful when you upload.
      </p>
      <p class="color-light">
        Deleting the fields will delete all the fields. You might want to
        prepare a backup if necessary.
      </p>
    </div>
    <Modal :modalActive="isModalActive" v-on:closeModal="toggleModal">
      <div class="modal-header">
        <h4>Upload Form Fields <code>.json</code> file</h4>
      </div>
      <div class="modal-content">
        <form v-on:submit.prevent="uploadFile">
          <Dropzone
            v-on:drop.prevent="dropFile"
            v-on:change="selectFile"
            name="fields-dropzone"
          />
          <span class="filename">{{ dropzoneFilename }}</span>
          <button type="submit">Upload</button>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useFormFieldStore } from "@/stores/formFields";
import Modal from "@/components/Modal.vue";
import Dropzone from "@/components/Dropzone.vue";

// Variables
const store = useFormFieldStore();
const isModalActive = ref(false);
const dropzoneFile = ref();
const dropzoneFilename = ref("No file selected.");

// Methods
const toggleModal = () => {
  isModalActive.value = !isModalActive.value;
};
const dropFile = (event) => {
  // The DataTransfer object is used to hold the data that is being dragged
  // during a drag and drop operation.
  // https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer
  dropzoneFile.value = event.dataTransfer.files[0];
  dropzoneFilename.value = dropzoneFile.value.name;
};
const selectFile = () => {
  // The querySelector() method returns the first Element within the document
  // that matches the specified selector. If not found, null is returned.
  // https://developer.mozilla.org/en-US/docs/Web/API/Document/querySelector
  dropzoneFile.value = document.querySelector(".fields-dropzone").files[0];
  dropzoneFilename.value = dropzoneFile.value.name;
};

const uploadFile = () => {
  console.log(dropzoneFile.value);
  store.postNewFields(dropzoneFile.value);
  toggleModal();
};

const deleteFormFields = () => {
  store.deleteFields();
};
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 1em;

  .btn {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 2.5em;

    @media screen and (min-width: 768px) {
      margin-top: 0px;
      margin-bottom: 0px;
      max-width: 250px;
    }
  }
}

.modal-header {
  border: none;

  code {
    background-color: #f8f9fa;
    padding: 0.25em;
  }
}
.modal-content {
  border: none;
  background-color: #212529;
  padding: 1em 1.5em;
  color: #f8f9fa;

  form {
    display: flex;
    flex-direction: column;
    row-gap: 1em;

    .filename {
      margin-top: 1em;
    }
    button {
      border: none;
      padding: 8px 0 5px 0;
      background-color: #41b883;
      color: #f8f9fa;
      &:hover {
        background-color: #f8f9fa;
        color: #212529;
      }
    }
  }
}
</style>
